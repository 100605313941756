//
// const EditIcon = ({width = "18px", height = "18px", color}) => {
//     return (<svg
//         fill={color ? color : "#6e86c4"}
//         viewBox="0 0 26 26"
//         width={`${width}`}
//         height={`${height}`}
//     >
//
const CreditCardIcon = () => {
    return (<svg
        fill="#000000"
        width="40px"
        height="40px"
        viewBox="0 0 946.812 946.812"

    >
		<path d="M106.812,428.258c0-38.6,31.4-70,70-70h677.8l-80.2-253.9c-7.5-23.6-32.8-36.8-56.5-29.3l-686.5,217
			c-23.7,7.5-36.8,32.8-29.3,56.5l104.8,331.4v-251.7H106.812z"/>
        <path d="M136.812,833.858c0,22.1,17.9,40,40,40h730c22.1,0,40-17.9,40-40v-54h-810V833.858L136.812,833.858z"/>
        <path
            d="M946.812,428.258c0-22.1-17.9-40-40-40h-42.7h-687.3c-22.1,0-40,17.9-40,40v276.5h199.7h610.3V428.258L946.812,428.258z"
        />
</svg>);
};

const ECheckIcon = ({}) => {
    return (<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                 // width="199.000000pt"
                 // height="124.000000pt"
                 height={'40px'}
                 viewBox="0 0 199.000000 124.000000"
                 preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,124.000000) scale(0.100000,-0.100000)"
           fill="#000000" stroke="none">
            <path d="M1257 1216 c-128 -47 -182 -191 -121 -315 78 -156 304 -159 391 -6
60 107 18 251 -89 306 -52 27 -132 33 -181 15z m146 -40 c62 -25 117 -112 117
-181 -1 -32 -35 -97 -66 -127 -79 -74 -203 -61 -271 29 -25 32 -28 45 -28 108
0 63 3 76 29 109 50 66 145 93 219 62z"/>
            <path d="M1363 1030 l-76 -81 -33 32 c-31 30 -54 32 -54 5 0 -6 19 -31 43 -54
l43 -43 87 95 c48 52 87 99 87 105 0 30 -28 14 -97 -59z"/>
            <path d="M1534 718 l-291 -293 -22 -76 -22 -76 -62 -6 c-35 -3 -95 -18 -139
-36 -42 -17 -87 -31 -101 -31 -43 0 -103 27 -186 84 -115 78 -165 90 -276 61
-102 -27 -241 -118 -228 -150 8 -22 31 -18 66 11 40 34 134 80 191 95 75 19
126 5 219 -59 92 -62 160 -92 213 -92 20 0 70 14 112 31 87 35 168 47 200 30
17 -9 40 -5 119 20 l98 31 283 281 c225 225 282 287 282 308 0 19 -18 43 -69
92 -38 37 -75 67 -83 67 -7 0 -144 -132 -304 -292z m139 -140 l-268 -268 -74
-25 c-41 -14 -76 -23 -78 -21 -2 1 6 33 17 69 20 65 24 69 292 342 l271 276
54 -53 53 -53 -267 -267z"/>
            <path d="M42 945 c-22 -16 -22 -18 -22 -280 0 -266 3 -291 35 -265 12 10 15
56 15 261 l0 249 511 0 510 0 -3 23 -3 22 -510 3 c-450 2 -513 0 -533 -13z"/>
            <path d="M1587 953 c-4 -3 -7 -15 -7 -25 0 -15 8 -18 50 -18 37 0 52 4 57 16
3 9 0 20 -8 25 -16 10 -83 12 -92 2z"/>
            <path d="M195 760 c-4 -6 -1 -17 5 -25 11 -13 79 -15 450 -15 241 0 445 3 454
6 9 3 16 15 16 25 0 18 -15 19 -459 19 -309 0 -462 -3 -466 -10z"/>
            <path d="M195 560 c-4 -6 -1 -17 5 -25 10 -13 63 -15 319 -15 168 0 312 4 320
9 8 5 11 16 8 25 -6 14 -42 16 -326 16 -211 0 -322 -4 -326 -10z"/>
            <path d="M27 293 c-13 -12 -8 -248 5 -261 9 -9 208 -12 834 -12 l823 0 15 22
c22 31 22 180 1 198 -26 22 -35 0 -35 -86 l0 -84 -800 0 -800 0 0 103 c0 67
-4 107 -12 115 -14 14 -22 15 -31 5z"/>
        </g>
    </svg>);
};



export {
    CreditCardIcon,
    ECheckIcon,
}
