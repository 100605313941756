import {ConvergePayment} from "./convergePayment";
import {NmiPayment} from "./nmiPayment";

export class PaymentFactory {
    static init({type, paymentGateway}) {
        switch (type) {
            case 'converge': {
                ConvergePayment.init(paymentGateway);
                break;
            }
            case 'NMI': {
                NmiPayment.init(paymentGateway);
                break;
            }
        }
    }
    create(type, data) {
        switch (type) {
            case 'converge': {
                return new ConvergePayment(data);
            }
            case 'NMI': {
                return new NmiPayment(data);
            }
        }
    }
}
