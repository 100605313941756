import React, {useEffect} from "react";
import {Routes, Route, useSearchParams} from "react-router-dom";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Checkout from "./Pages/Checkout";
import Footer from "./Pages/Footer";
import Header from "./Pages/Header";
import Success from "./Pages/Success";
import {executeGet} from "./Api";
import Receipt from "./Pages/Receipt";


const App = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [logo, setLogo] = React.useState(null);
    const [paymentGateways, setPaymentGateways] = React.useState(null);
    const [getInfo, setGetInfo] = React.useState(null);
    // useEffect(() => {
    //     if (window.location.href.indexOf('/api/payment-request/create') > -1) {
    //         executeGet("payment-request/create?fromXHR=true&amount=" + searchParams.get("amount"))
    //             .then((res) => {
    //                 console.log(res.data);
    //                 window.location.href = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + '?amount=' + res.data.amount + '&token=' + res.data.token;
    //             });
    //     } else if (window.location.href.indexOf('/api/payment-request/verification-create') > -1) {
    //         executeGet("payment-request/verification-create?fromXHR=true&token=" + searchParams.get("token"))
    //             .then((res) => {
    //                 window.location.href = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + '?amount=' + res.data.amount + '&token=' + res.data.token;
    //             });
    //     }
    // }, []);

    useEffect(() => {
        const isLocalhost = window.location.href.indexOf('localhost') > -1;

        let currentHostname = window.location.origin
            .replace('www.', '')
            .replace('http://', '')
            .replace('https://', '');

        executeGet(`portal/get-info?url=${isLocalhost? window.currentHostname : currentHostname}`)
            .then((res) => {
                if (res.data.logo) {
                    setLogo(res.data.logo);
                }
                if (res.data.primaryColor) {
                    document.documentElement.style.setProperty('--primary-color', res.data.primaryColor);
                }
                setPaymentGateways(res.data.paymentGateways);
                setGetInfo(res.data);
                let link = document.querySelector("link[rel~='icon']");
                link.href = res.data.logo;
                let title = document.querySelector("title");
                title.innerHTML = res.data.companyName;
            });
    }, []);

    return (
        <div>
            <Header logo={logo}/>
            {paymentGateways && <div className="container">

                <Routes>
                    <Route exact path="/" element={<Checkout paymentGateways={paymentGateways} companyName={getInfo && getInfo.companyName}/>}/>
                    <Route exact path="checkout" element={<Checkout paymentGateways={paymentGateways} companyName={getInfo && getInfo.companyName}/>}/>
                    <Route exact path="success" element={<Success/>}/>
                    <Route exact path="receipt" element={<Receipt/>}/>

                </Routes>
            </div>}
            <Footer managerName={getInfo && getInfo.managerName} companyName={getInfo && getInfo.companyName} humanFriendlyName={getInfo && getInfo.humanFriendlyName}/>
        </div>
    );

};

export default App;
