import {executeGet, executePost} from "../Api";
import {generateCaptchaAgain} from "../utils/captchaRegenrator";

export class NmiPayment {

    data;
    token = null;
    attemptToken = null;
    currentHostname = null;

    resolve;
    reject;

    constructor(data) {
        this.data = data;
        this.paymentMethod = data.paymentMethod;
        this.paymentGateway = data.paymentGateway;
        this.currentHostname = window.location.origin
            .replace('www.', '')
            .replace('http://', '')
            .replace('https://', '');
        if (window.location.href.indexOf('localhost') > -1) {
            this.currentHostname = window.currentHostname;
        }
    }

    static init(paymentGateway) {
        const script = document.createElement('script');

        script.src = paymentGateway.collectJsScriptSrc;
        script.setAttribute("data-tokenization-key", paymentGateway.tokenizationKey);
        script.setAttribute("data-primary-color", "#1B7873");
        script.setAttribute("data-secondary-color", "#1B7873");
        script.setAttribute("data-theme", "bootstrap");
        // script.setAttribute("data-field-google-pay-selector", ".google-pay-button");
        // script.setAttribute("data-field-google-pay-shipping-address-required", "true");
        // script.setAttribute("data-field-google-pay-shipping-address-parameters-phone-number-required", "true");
        // script.setAttribute("data-field-google-pay-billing-address-required", "true");
        // script.setAttribute("data-field-google-pay-billing-address-parameters-phone-number-required", "true");
        // script.setAttribute("data-field-google-pay-billing-address-parameters-format", "FULL");
        // script.setAttribute("data-field-google-pay-email-required", "true");
        // script.setAttribute("data-field-apple-pay-selector", ".apple-pay-button");
        // script.setAttribute("data-field-apple-pay-shipping-type", "delivery");
        // script.setAttribute("data-field-apple-pay-required-billing-contact-fields", '["postalAddress","name"]');
        // script.setAttribute("data-field-apple-pay-required-shipping-contact-fields", '["postalAddress","name"]');
        // script.setAttribute("data-field-apple-pay-contact-fields", '["phone","email"]');
        // script.setAttribute("data-field-apple-pay-contact-fields-mapped-to", "billing");
        // script.setAttribute("data-field-apple-pay-total-label", "Total");
        // script.setAttribute("data-field-apple-pay-type", "buy");
        // script.setAttribute("data-field-apple-pay-style-button-style", "white-outline");
        // script.setAttribute("data-field-apple-pay-style-height", "40px");
        // script.setAttribute("data-field-apple-pay-style-border-radius", "4px");
        script.setAttribute("data-country", "US");
        script.setAttribute("data-currency", "USD");

        document.body.appendChild(script);
    }

    callBodyguard = async () => {
        const captchaToken = await generateCaptchaAgain();
        let result = await executePost(`payment-attempt?url=${this.currentHostname}`, {
            name: this.data.firstName + ' ' + this.data.lastName,
            phone: this.data.phone,
            amount: this.getAmount(),
            captchaToken,
            email: this.data.email,
            hostname: window.location.hostname,
            'user-agent': window.navigator.userAgent,
            address: {
                address: this.data.address,
                city: this.data.city,
                state: this.data.state,
                zip: this.data.zip,
                country: this.data.country || 'US'
            }
        });
        if (result.data.status !== "APPROVED") {
            this.reject({message: `Your payment was not approved. Please try again.`});
            return;
        }
        this.attemptToken = result.data.token;
        this.attemptTokenStatus = result.data.status;
    }

    pay = async () => {
        return new Promise(async (resolve, reject) => {
            this.getAmount();
            this.resolve = resolve;
            this.reject = reject;


            const callback = async (response) => {
                await this.callBodyguard();

                if (this.attemptTokenStatus !== 'APPROVED') {
                    throw new Error("Your payment was not approved. Please try again");
                }

                response.wallet.email = this.data.email;

                response.wallet.billingInfo = {
                    address1: this.data.address || null,
                    // address2: paymentData.address2 || null,
                    firstName: this.data.firstName || null,
                    lastName: this.data.lastName || null,
                    postalCode: this.data.zip || null,
                    city: this.data.city || null,
                    state: this.data.state || null,
                    country: this.data.country || null,
                    phone: this.data.phone || null,
                };

                try {
                    const captchaToken1 = await generateCaptchaAgain();
                    const transactionResponse = await executePost(`payment-gateway/create-transaction?url=${this.currentHostname}`, {
                        amount: this.getAmount(),
                        cardData: {
                            paymentData: this.data,
                            paymentResponse: response,
                        },
                        paymentMethod: this.paymentMethod,
                        paymentGatewayId: this.data.paymentGateway.paymentGatewayId,
                        attemptToken: this.attemptToken,
                        captchaToken: captchaToken1,
                        // verificationCode,
                        extra: {
                            ...this.data?.extra || {},
                        },
                    });

                    const captchaToken2 = await generateCaptchaAgain();
                    await executePost(`transaction?url=${this.currentHostname}`, {
                        ...this.data,
                        attemptToken: this.attemptToken,
                        amount: this.getAmount(),
                        captchaToken: captchaToken2,
                        firstName: this.data.firstName,
                        lastName: this.data.lastName,
                        phone: this.data.phone,
                        address: {
                            address: this.data.address,
                            city: this.data.city,
                            state: this.data.state,
                            zip: this.data.zip
                        },
                        email: this.data.email,
                        paymentMethod: this.paymentMethod,
                        paymentGateway: this.data.paymentGateway.name,
                        paymentGatewayId: this.data.paymentGateway.paymentGatewayId,
                        transactionId: transactionResponse?.data?.transaction?.transactionid || '',
                        // items,
                        response: transactionResponse?.data?.transaction || {},
                        userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    }).then((res) => {
                        this.resolve(res.data.redirectUrl);
                    }).catch((err) => {
                        console.error('err #lskdwlkj3lkj');
                        console.error(err);
                        if (err?.response?.data?.error) {
                            this.reject({message: err.response.data.error});
                        } else {
                            this.reject(err);
                        }
                    });
                } catch (err) {
                    console.error('err #pwpeo21o3');
                    console.error(err);
                    reject({message: err?.response?.data?.error || err?.message || "Transaction error."});
                }
            }


            try {
                window.CollectJS.configure({
                    // 'paymentSelector' : '#customPayButton',
                    // 'theme': 'bootstrap',
                    // 'primaryColor': '#ff288d',
                    // 'secondaryColor': '#ffe200',
                    // 'buttonText': 'SUBMIT ME!',
                    // 'instructionText': 'Enter Card Info Below',
                    'paymentType': this.paymentMethod === 'card' ? 'cc' : 'ck',
                    variant: 'lightbox',
                    price: this.getAmount(),
                    currency: 'USD',
                    country: 'US',
                    callback: callback
                });

                // closePaymentRequest
                // window.CollectJS.onError = () => {
                //     debugger;
                //     this.reject({message: "Payment was canceled."});
                // }
                // window.CollectJS.onPaymentCanceled = () => {
                //     debugger;
                //     this.reject({message: "Payment was canceled."});
                // }
                window.CollectJS.startPaymentRequest();

            } catch (err) {
                console.error('err #lwkel2k3');
                console.error(err);
                this.reject(err.response.data);
            }
        });
    }

    async retrieveToken() {
        try {
            let captchaToken = await generateCaptchaAgain();
            const res = await executeGet("payment-gateway/token?amount=" + this.getAmount()
                + '&isEcheck=' + (this.paymentMethod === 'eCheck') + '&attemptToken=' + this.attemptToken + `&captchaToken=${captchaToken}&paymentMethod=${this.paymentMethod}`
                + `&url=${this.currentHostname}`);
            return res.data.token;
        } catch (err) {
            if (err?.response?.data?.error) {
                this.reject({message: err.response.data.error});
            } else {
                this.reject(err);
            }
        }
        return null;
    }

    getAmount() {
        if ((this.data.totalAmountDue || this.data.amount) === 0) {
            return "0.01";
        }
        if (isNaN(this.data.totalAmountDue || this.data.amount)) {
            return Number((this.data.totalAmountDue || this.data.amount).replace(/\D/g, '')).toFixed(2);
        } else {
            return Number(this.data.totalAmountDue || this.data.amount).toFixed(2);
        }
    }


    serializeExpirationDate() {
        if (this.data.expirationDate.length === 4) {
            return this.data.expirationDate;
        }
        let dates = this.data.expirationDate.split('/');
        if (dates[1].length === 2) {
            return dates[0] + dates[1];
        }
        return dates[0] + dates[1].slice(-2);
    }

    async sendTransaction(data) {
        let captchaToken = await generateCaptchaAgain();
        return executePost(`transaction?url=${this.currentHostname}`, {
            ...data,
            address: {
                address: this.data.address,
                city: this.data.city,
                state: this.data.state,
                zip: this.data.zip,
                country: this.data.country || 'US'
            },
            attemptToken: this.attemptToken,
            cardNumber: null,
            captchaToken,
            cvv: null,
            bankAccountNumber: null,
            userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }).then((res) => {
            this.resolve(res.data.redirectUrl);
        }).catch((err) => {
            if (err?.response?.data?.error) {
                this.reject({message: err.response.data.error});
            } else {
                this.reject(err);
            }
        });
    }


}
