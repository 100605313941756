import React, {useEffect, useState} from 'react'
import 'reactjs-popup/dist/index.css';
import './Checkout.css'
import {useSearchParams} from "react-router-dom";
import Popup from "reactjs-popup";
import {PaymentFactory} from "../../payment/paymentFactory";
import {states} from "./states";
import {FormValidation} from "./formValidation";
import {executeGet, executePost} from "../../Api";
import {amountFormatterWithZero, amountFormatter} from "../../utils";
import {CreditCardIcon, ECheckIcon} from "../../components/Icons.js";

const Checkout = ({paymentGateways, companyName}) => {

    const [achPopupOpen, setAchPopupOpen] = useState(false);
    const [isAchAgree, setIsAchAgree] = useState(false);

    const [paymentMethod, setPaymentMethod] = useState('');
    const [selectedPaymentGateway, setSelectedPaymentGateway] = useState(null);

    const [fields, setFields] = useState(null);

    const [showSpinner, setShowSpinner] = useState(false);
    const [showPaymentMethods, setShowPaymentMethods] = useState([]);
    const [onlinePaymentFee, setOnlinePaymentFee] = useState({
        feeName: "Processing Fee", feePercentage: 0.0, optional: false, checkboxText: '', feeForPaymentMethod: '',
    });
    const [onlinePaymentFeeAmount, setOnlinePaymentFeeAmount] = useState(0);
    const [willPayFee, setWillPayFee] = useState(false);
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorTitle, setErrorTitle] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const [amount, setAmount] = useState('');
    const [totalAmountDue, setTotalAmountDue] = useState('');
    const [inputWithFeeHasFocus, setInputWithFeeHasFocus] = useState(!searchParams.get("amount"));

    const [readOnlyFields, setReadOnlyFields] = useState([]);
    const [isAmountReadOnly, setIsAmountReadOnly] = useState(searchParams.get("amount"));
    const [form, setForm] = useState({
        card: '',
        cvv: '',
        expirationMonth: '',
        expirationYear: '',
        paymentAccount: 'personal-account',
        routingNumber: '',
        accountNumber: '',
        cashPayment: '',
        company: '',
        firstName: '',
        lastName: '',
        address1: '', // address2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        email: '',
        phone: '',
        agree: false,
        extra: {},
        cash: '',
    });


    useEffect(() => {
        if (selectedPaymentGateway) {
            PaymentFactory.init({type: selectedPaymentGateway.type, paymentGateway: selectedPaymentGateway});
        }
    }, [paymentMethod, selectedPaymentGateway]);


    useEffect(() => {
        const allPaymentMethods = paymentGateways.map(o => o.showPaymentMethods).flat();
        const uniquePaymentMethods = allPaymentMethods.filter((item, index) => allPaymentMethods.indexOf(item) === index);

        setShowPaymentMethods(uniquePaymentMethods);
        // setPaymentMethod(uniquePaymentMethods && uniquePaymentMethods.length > 0 && uniquePaymentMethods[0]);
        setPaymentMethod(uniquePaymentMethods && uniquePaymentMethods.length > 0 && uniquePaymentMethods.find(o => o === 'card'));
        const chosenPaymentGateway = paymentGateways && paymentGateways.length > 0 && paymentGateways.find(o => o.showPaymentMethods.includes('card'));
        setSelectedPaymentGateway(chosenPaymentGateway);

        setOnlinePaymentFee({
            feeName: chosenPaymentGateway.onlinePaymentFee.feeName || 'Processing Fee',
            feePercentage: chosenPaymentGateway.onlinePaymentFee.feePercentage,
            feeForPaymentMethod: chosenPaymentGateway.onlinePaymentFee.feeForPaymentMethod,
            optional: !!chosenPaymentGateway.onlinePaymentFee.optional,
            checkboxText: chosenPaymentGateway.onlinePaymentFee.checkboxText,
        });

    }, []);


    useEffect(() => {
        if (onlinePaymentFee && onlinePaymentFee.feePercentage > 0) {

            let feeAmount = (paymentMethod === 'card') ? parseFloat(onlinePaymentFee.feePercentage) * parseFloat(amount || parseFloat(searchParams.get("amount")) || 0) : 0;

            feeAmount = parseFloat(amountFormatter(feeAmount));

            setOnlinePaymentFeeAmount(feeAmount);

            if (feeAmount && (!onlinePaymentFee.optional || (onlinePaymentFee.optional && form.extra.activateOnlinePaymentFee))) {
                setWillPayFee(true);

            }


            const totalAmountDue = (parseFloat(amount || searchParams.get("amount")) + (feeAmount ? feeAmount : 0));
            setTotalAmountDue(totalAmountDue);

        }
    }, [amount, onlinePaymentFee, paymentMethod, form.extra.activateOnlinePaymentFee]);

    useEffect(() => {
        if (searchParams.get("amount")) {
            setIsAmountReadOnly(true);
        }
    }, []);


    const closeModal = () => setOpen(false);

    const closeAchPopup = () => {
        setPaymentMethod('card');
        setSelectedPaymentGateway(paymentGateways.find(o => o.showPaymentMethods.includes('card')));
        setAchPopupOpen(false)
        setIsAchAgree(false);
    };

    const updatePaymentTab = (e, paymentType) => {
        e.preventDefault();
        setPaymentMethod(paymentType);
        setSelectedPaymentGateway(paymentGateways.find(o => o.showPaymentMethods.includes(paymentType)));
    }

    const showError = ({errorMessage, errorTitle}) => {
        setErrorMessage(errorMessage);
        setErrorTitle(errorTitle);
        setOpen(true);
    }

    const formValidation = FormValidation({form, showError,});

    const sanitizeExpiration = (expMonth, expYear) => {
        try {

            expMonth = expMonth.replace(/\D/g, '');
            expYear = expYear.replace(/\D/g, '');

            if (expMonth.length === 1) {
                expMonth = '0' + expMonth;
            }
            expMonth = expMonth.slice(0, 2);
            expYear = expYear.slice(-2);
            return expMonth + expYear;
        } catch (err) {
            console.error(err);
            return expMonth + expYear;
        }
    };

    const pay = async (e) => {
        e.preventDefault();
        if ((selectedPaymentGateway.type === 'NMI' || formValidation.validate(paymentMethod)) && formValidation.validate('form')) {
            const factory = new PaymentFactory();
            let amountToPay = (amount || searchParams.get("amount"));
            if (willPayFee) {
                amountToPay = (parseFloat(amountToPay) + parseFloat(onlinePaymentFeeAmount));
            }
            amountToPay = Number(amountToPay).toFixed(2);
            let extra = {};
            if (form.extra) {
                extra = {...form.extra};
            }
            for (let params of searchParams.keys()) {
                extra[params] = searchParams.get(params);
            }
            if (window.LogRocket) {
                window.LogRocket.identify(`${form.email}`, {
                    name: `${form.firstName} ${form.lastName}`,
                    email: `${form.email}`,
                    phone: `${form.phone}`,
                    franchise: searchParams.get("franchise"),
                });
            }
            const transactionData = {
                paymentGateway: selectedPaymentGateway,
                amount: paymentMethod === 'cash' ? form.cash : amountToPay,
                onlinePaymentFeeAmount: onlinePaymentFeeAmount,
                totalAmountDue: amountToPay,
                onlinePaymentFeeName: onlinePaymentFee.feeName || 'Processing Fee',
                firstName: form.firstName,
                lastName: form.lastName,
                email: form.email,
                phone: form.phone,
                company: form.company,
                cardNumber: form.card,
                cvv: form.cvv,
                address: form.address1,
                city: form.city,
                state: form.state,
                zip: form.zip,
                expirationDate: sanitizeExpiration(form.expirationMonth, form.expirationYear),
                bankRoutingNumber: form.routingNumber && form.routingNumber.replaceAll(' ', ''),
                bankAccountNumber: form.accountNumber && form.accountNumber.replaceAll(' ', ''),
                bankAccountType: form.paymentAccount,
                extra,
                paymentMethod,
                franchise: searchParams.get("franchise")
            };

            const payment = factory.create(selectedPaymentGateway.type, transactionData);
            setShowSpinner(true);
            try {
                const url = await payment.pay(e);
                if (url.indexOf('localhost') > -1) {
                    window.location.href = "http://localhost:3000/success";
                } else {
                    window.location.href = url.indexOf('https://') === -1 ? 'https://' + url : url;
                }
            } catch (error) {
                setShowSpinner(false);
                console.dir('error')
                console.dir(error)
                console.dir('error.message')
                console.dir(error.message)


                showError({errorMessage: error.message || error});
            }
            setShowSpinner(false);
        }
    }

    useEffect(() => {
        if (errorMessage && errorMessage.length > 0) {
            setTimeout(async () => {
                let currentHostname = window.location.origin
                    .replace('www.', '')
                    .replace('http://', '')
                    .replace('https://', '');
                if (window.location.href.indexOf('localhost') > -1) {
                    currentHostname = window.currentHostname;
                }

                try {
                    await executePost(`declined-transaction`, {
                        name: form.firstName + ' ' + form.lastName,
                        email: form.email,
                        phone: form.phone.indexOf('+1') > -1 ? form.phone : '+1' + form.phone,
                        hostname: currentHostname,
                        amount: (amount || searchParams.get("amount")),
                        'user-agent': window.navigator.userAgent,
                        address: JSON.stringify({
                            address: form.address, city: form.city, state: form.state, zip: form.zip, country: 'US'
                        }),
                        error: errorMessage,
                    });
                } catch (e) {

                }
            });
        }
    }, [errorMessage]);

    return (<div className="container-fluid overflow-auto">

            <section className="top-section">

                <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="d-flex flex-center">
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0" y="0"
                             viewBox="0 0 512 512" className="lock">
                            <g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                    <g>
                                        <path
                                            d="M400,188h-36.037v-82.23c0-58.322-48.449-105.77-108-105.77c-59.551,0-108,47.448-108,105.77V188H112    c-33.084,0-60,26.916-60,60v204c0,33.084,26.916,60,60,60h288c33.084,0,60-26.916,60-60V248C460,214.916,433.084,188,400,188z     M187.963,105.77c0-36.266,30.505-65.77,68-65.77s68,29.504,68,65.77V188h-136V105.77z M420,452c0,11.028-8.972,20-20,20H112    c-11.028,0-20-8.972-20-20V248c0-11.028,8.972-20,20-20h288c11.028,0,20,8.972,20,20V452z"
                                            fill="#000000" data-original="#000000"></path>
                                    </g>
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                    <g>
                                        <path
                                            d="M256,286c-20.435,0-37,16.565-37,37c0,13.048,6.76,24.51,16.963,31.098V398c0,11.045,8.954,20,20,20    c11.045,0,20-8.955,20-20v-43.855C286.207,347.565,293,336.08,293,323C293,302.565,276.435,286,256,286z"
                                            fill="#000000" data-original="#000000"></path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <span className="checkout-text">Secure checkout process</span>
                    </div>
                </div>

                <div className="row d-flex flex-column justify-content-center align-items-center">
                    <div className="amount-due-block">

                        {/*important booleans we can use to construct clear logic here:*/}
                        {/*1. There is a fee on this payment gateway*/}
                        {/*2. There is a fee on the payment type the user has selected ("card", "eCheck", etc.)*/}
                        {/*3. The fee is optional and the user has accepted the fee*/}
                        {/*4. The fee is optional and the user has not accepted the fee*/}
                        {/*5. The fee is required*/}

                        <p className="amount-due">Amount Due</p>

                        {+onlinePaymentFee.feePercentage > 0 && paymentMethod !== 'cash' && ((!!onlinePaymentFee.optional && form.extra.activateOnlinePaymentFee) || !onlinePaymentFee.optional) && (<>
                            {onlinePaymentFeeAmount ? <p>
                                <span className="">{onlinePaymentFee.feeName || 'Processing Fee'}: </span>
                                <span className="">&#36;{amountFormatterWithZero(onlinePaymentFeeAmount)}
                            </span></p> : <></>}

                            {totalAmountDue > 0 && <>
                                <p className={`amount-due-no-order ${!inputWithFeeHasFocus ? "amount-due-big-font" : ""}`}>Total
                                    Amount Due</p>
                                <p className={`amount-no-order ${!inputWithFeeHasFocus ? "amount-big-font" : ""}`}>&#36;{amountFormatterWithZero(totalAmountDue)}</p></>}
                        </>)}


                        {+onlinePaymentFee.feePercentage > 0 && (!!onlinePaymentFee.optional && !form.extra.activateOnlinePaymentFee) && (<>
                            {totalAmountDue > 0 && <>
                                <p className={`amount-due-no-order ${!inputWithFeeHasFocus ? "amount-due-big-font" : ""}`}>Total
                                    Amount Due</p>
                                <p className={`amount-no-order ${!inputWithFeeHasFocus ? "amount-big-font" : ""}`}>&#36;{amountFormatterWithZero(amount)}</p></>}
                        </>)}

                        {!(+onlinePaymentFee.feePercentage > 0 && paymentMethod !== 'cash') && (<>
                            {isAmountReadOnly && !searchParams.get("amount") && (
                                <p className={`amount-no-order ${!inputWithFeeHasFocus ? "amount-big-font" : ""}`}>&#36;{amountFormatterWithZero(amount)}</p>)}
                            {isAmountReadOnly && searchParams.get("amount") && (
                                <span className="amount">&#36;{searchParams.get("amount")}</span>)}
                        </>)}

                        {!isAmountReadOnly && !searchParams.get("amount") && (
                            <span className="amount">&#36;<input type="text"
                                                                 pattern="[0-9\.]*"
                                                                 className="no-outline full-width text-center no-spinners"
                                                                 placeholder="Enter Amount Due"
                                                                 value={amount}
                                                                 onChange={(e) => setAmount(+e.target.value)}/></span>)}

                        {+onlinePaymentFee.feePercentage > 0 && !!onlinePaymentFee.optional && (<>
                            <div className="flex-center">
                                <input type="checkbox" id='optional' value={form.extra.activateOnlinePaymentFee}
                                       onChange={(e) => {
                                           setForm({
                                               ...form,
                                               extra: {...form.extra, activateOnlinePaymentFee: e.target.checked}
                                           });
                                           if (e.target.checked) {
                                               setTotalAmountDue((parseFloat(amount || searchParams.get("amount")) + onlinePaymentFeeAmount));
                                           } else {
                                               setTotalAmountDue(parseFloat(amount || searchParams.get("amount")));
                                           }
                                       }}/>
                                <label htmlFor='optional'
                                       className="m-l-10 m-t-0 bold max-width">{onlinePaymentFee.checkboxText.replace('{amount}', '$' + amountFormatterWithZero(onlinePaymentFeeAmount))}</label>
                            </div>
                        </>)}

                    </div>

                    {fields && fields.length > 0 && (<div className="billing-information-block last-block">
                        <p className="amount-due">More Information</p>

                        {fields.map((o, i) => {
                            return (<div className="full-width" key={i}>
                                <label>{o.name}</label>
                                {o.type === 'input' && (<input type="text" className="no-outline full-width"
                                                               placeholder={"Enter " + o.name}
                                                               value={form.extra[o.name]}
                                                               onChange={(e) => setForm({
                                                                   ...form,
                                                                   extra: {...form.extra, [o.name]: e.target.value}
                                                               })}/>)}
                                {o.type === 'checkbox' && (<div className="form-check">
                                    <input type="checkbox" id={o.name} value={form.extra[o.name]}
                                           onChange={(e) => setForm({
                                               ...form, extra: {...form.extra, [o.name]: e.target.value}
                                           })}/>
                                    <label htmlFor={o.name} className="m-l-10 m-t-0 bold">{o.name}</label>
                                </div>)}
                                {o.type === 'select' && (<select className="full-width no-outline"
                                                                 value={form.extra[o.name]}
                                                                 onChange={(e) => setForm({
                                                                     ...form, extra: {
                                                                         ...form.extra, [o.name]: e.target.value
                                                                     }
                                                                 })}>
                                    <option>Select</option>
                                    {o.options.map(item => {
                                        return (<option key={item} value={item}>{item}</option>);
                                    })}
                                </select>)}

                            </div>)
                        })}
                    </div>)}


                    <div className="billing-information-block">
                        <>

                            <p className="amount-due">Select Payment Method</p>


                            <div className="row full-width-a row-gutter-3rem" style={{marginTop: '20px'}}>
                                {showPaymentMethods.indexOf('card') > -1 && (<div className="col-12 col-md-3"
                                                                                  onClick={(e) => updatePaymentTab(e, 'card')}
                                >
                                    <div style={{height: '40px', marginBottom: '6px'}}
                                         className={'center-payment-method'}
                                    >
                                        <CreditCardIcon/>
                                    </div>
                                    <a
                                        className={`center-payment-method ${paymentMethod === 'card' ? 'active' : ''}`}> Credit
                                        Card </a>
                                </div>)}


                                {showPaymentMethods.indexOf('eCheck') > -1 && (<div className="col-6 col-md-2"
                                                                                    onClick={(e) => updatePaymentTab(e, 'eCheck')}
                                >
                                    <div style={{height: '40px', marginBottom: '6px'}}
                                         className={'center-payment-method'}>
                                        <ECheckIcon/>
                                    </div>
                                    <a onClick={(e) => updatePaymentTab(e, 'eCheck')}
                                       className={`center-payment-method ${paymentMethod === 'eCheck' ? 'active' : ''}`}> eCheck </a>
                                </div>)}

                                {showPaymentMethods.indexOf('cash') > -1 && (<div className="col-6 col-md-2">
                                    <a onClick={(e) => updatePaymentTab(e, 'cash')}
                                       className={paymentMethod === 'cash' ? 'active' : ''}> Cash </a>
                                </div>)}
                            </div>
                        </>

                    </div>


                    {selectedPaymentGateway && selectedPaymentGateway.type !== 'NMI' &&
                        <div className="billing-information-block">

                            <p className="amount-due">Payment Information</p>
                            {paymentMethod === 'card' && (<>
                                <div className="full-width">
                                    <label>Credit Card Number*</label>
                                    <input type="text" className="no-outline full-width"
                                           placeholder="Enter Credit Card Number"
                                           value={form.card}
                                           pattern="[0-9]*"
                                           onChange={(e) => setForm({...form, card: e.target.value})}/>
                                </div>

                                <div>
                                    <div className="flex">
                                        <div className="flex-item w-200">
                                            <label>CVV2*</label>

                                            <input type="text" className="no-outline full-width no-spinners"
                                                   placeholder="Enter CVV2"
                                                   value={form.cvv}
                                                   pattern="[0-9]*"
                                                   onChange={(e) => setForm({...form, cvv: e.target.value})}/>
                                        </div>
                                        <div className="flex-item w-200">
                                            <label>Exp Month*</label>
                                            <input type="text" className="no-outline full-width"
                                                   placeholder="MM"
                                                   value={form.expirationMonth}
                                                   onChange={(e) => setForm({
                                                       ...form, expirationMonth: e.target.value
                                                   })}
                                            />
                                        </div>
                                        <div className="flex-item w-200">
                                            <label>Exp Year*</label>
                                            <input type="text" className="no-outline full-width"
                                                   placeholder="YY"
                                                   value={form.expirationYear}
                                                   onChange={(e) => setForm({
                                                       ...form, expirationYear: e.target.value
                                                   })}
                                                   onBlur={(e) => {
                                                       sanitizeExpiration(form.expirationMonth, form.expirationYear);
                                                   }}
                                            />
                                        </div>
                                    </div>
                                    <small>Typically on back of card</small>
                                </div>
                            </>)}


                            {paymentMethod === 'eCheck' && (<>
                                <div className="row full-width-a">
                                    <div className="col col-md-4">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                   name="personal-account" id="personal-account"
                                                   value="personal-account"
                                                   onChange={(e) => setForm({
                                                       ...form, paymentAccount: 'personal-account'
                                                   })}
                                                   checked={form.paymentAccount === "personal-account"}/>
                                            <label className="form-check-label radio-label"
                                                   htmlFor="personal-account">
                                                Personal Account
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col col-md-6">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                   name="business-account" id="business-account"
                                                   value="business-account"
                                                   onChange={(e) => setForm({
                                                       ...form, paymentAccount: 'business-account'
                                                   })}
                                                   checked={form.paymentAccount === "business-account"}/>
                                            <label className="form-check-label radio-label"
                                                   htmlFor="business-account">
                                                Business Account
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <label>Bank Routing Number**</label>
                                    <input type="text" className="no-outline full-width"
                                           placeholder="Enter Financial Institution/Bank Routing Number"
                                           value={form.routingNumber}
                                           pattern="[0-9]*"
                                           onChange={(e) => setForm({...form, routingNumber: e.target.value})}/>
                                </div>

                                <div className="row">
                                    <label>Bank Account Number**</label>
                                    <input type="text" className="no-outline full-width"
                                           placeholder="Enter Financial Institution/Bank Account Number*"
                                           value={form.accountNumber}
                                           pattern="[0-9]*"
                                           onChange={(e) => setForm({...form, accountNumber: e.target.value})}/>
                                </div>
                                <div className="row m-t-20">
                                    <div className="flex-center">
                                        <input type="checkbox" id='agree' value={form.agree}
                                               onChange={(e) => setForm({...form, agree: e.target.value})}/>
                                        <label htmlFor='agree' className="m-l-10 m-t-0 bold">I Agree</label>
                                    </div>
                                    <p>By Clicking the “I Agree” box, you authorize {companyName || 'us'} to use
                                        information from your check to initiate a one-time fund transfer from your
                                        account or to process the payment as a check transaction or bank drawn draft
                                        from your account for the amount of
                                        ${amountFormatterWithZero(totalAmountDue || 0)}.
                                        If your payment is
                                        returned due to insufficient funds, you authorize us to make a one-time
                                        electronic funds transfer or to use a bank draft drawn from your account to
                                        collect a fee as allowed by state law.</p>
                                </div>

                            </>)}


                            {paymentMethod === 'cash' && (<>

                                <div className="row">
                                    <p className="amount">&#36;<input type="text"
                                                                      className="no-outline full-width text-center"
                                                                      placeholder="Cash Amount"
                                                                      value={form.cash}
                                                                      pattern="[0-9\.]*"
                                                                      onChange={(e) => setForm({
                                                                          ...form, cash: e.target.value
                                                                      })}/></p>
                                </div>

                                <div className="row">
                                    <label>Has a cash payment been made?</label>
                                </div>


                                <div className="row full-width-a">
                                    <div className="col col-md-3">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="cash-payment-yes"
                                                   id="cash-payment-yes" value="yes"
                                                   checked={form.cashPayment === "yes"}
                                                   onChange={(e) => setForm({...form, cashPayment: "yes"})}/>
                                            <label className="form-check-label radio-label"
                                                   htmlFor="cash-payment-yes">
                                                Yes
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col col-md-3">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="cash-payment-no"
                                                   id="cash-payment-no"
                                                   value="no" checked={form.cashPayment === "no"}
                                                   onChange={(e) => setForm({...form, cashPayment: "no"})}/>
                                            <label className="form-check-label radio-label"
                                                   htmlFor="cash-payment-no">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>


                            </>)}


                        </div>}


                    <div
                        className={`billing-information-block ${selectedPaymentGateway && selectedPaymentGateway.type === 'NMI' ? 'last-block' : ''}`}>
                        <p className="amount-due">Billing Information</p>

                        <div className="full-width">
                            <label>Company</label>
                            <input type="text" className="no-outline full-width" placeholder="Enter company name"
                                   value={form.company} onChange={(e) => setForm({...form, company: e.target.value})}/>
                        </div>
                        <div className="flex">
                            <div className="flex-item">
                                <label>First Name*</label>
                                <input type="text" className="no-outline full-width" placeholder="Enter first name"
                                       value={form.firstName}
                                       onChange={(e) => setForm({...form, firstName: e.target.value})}/>
                            </div>
                            <div className="flex-item">
                                <label>Last Name*</label>
                                <input type="text" className="no-outline full-width" placeholder="Enter last name"
                                       value={form.lastName}
                                       onChange={(e) => setForm({...form, lastName: e.target.value})}/>
                            </div>
                        </div>

                        <div className="full-width">
                            <label>Address*</label>
                            <input type="text" className="no-outline full-width" placeholder="Enter address"
                                   value={form.address1}
                                   onChange={(e) => setForm({...form, address1: e.target.value})}/>
                        </div>

                        {/*<div className="full-width">*/}
                        {/*    <label>Address2</label>*/}
                        {/*    <input type="text" className="no-outline full-width" placeholder="Enter address"*/}
                        {/*           value={form.address2}*/}
                        {/*           onChange={(e) => setForm({...form, address2: e.target.value})}/>*/}
                        {/*</div>*/}

                        <div className="flex wrap-mobile">
                            <div className="flex-item w-220">
                                <label>City*</label>
                                <input type="text" className="full-width no-outline" placeholder="Enter city"
                                       value={form.city} onChange={(e) => setForm({...form, city: e.target.value})}/>
                            </div>

                            <div className="flex-item w-auto">
                                <label>State/Province*</label>
                                <select className="full-width no-outline"
                                        value={form.state} onChange={(e) => setForm({...form, state: e.target.value})}>
                                    <option>Select</option>
                                    {states.map(item => {
                                        return (<option key={item} value={item}>{item}</option>);
                                    })}
                                </select>
                            </div>

                            <div className="flex-item w-135">
                                <label>Zip/Postal Code*</label>
                                <input type="text" className="full-width no-outline"
                                       placeholder="Enter zip or postal code"
                                       value={form.zip} onChange={(e) => setForm({...form, zip: e.target.value})}/>
                            </div>
                        </div>

                        <div className="flex m-b-20">
                            <div className="flex-item">
                                <label>Email Address*</label>
                                <input type="email" className="no-outline full-width" placeholder="Enter email address"
                                       value={form.email} onChange={(e) => setForm({...form, email: e.target.value})}/>
                            </div>
                            <div className="flex-item">
                                <label>Phone Number*</label>
                                <input type="tel" className="no-outline full-width" placeholder="Enter phone number"
                                       value={form.phone} onChange={(e) => setForm({...form, phone: e.target.value})}/>
                            </div>
                        </div>

                        {selectedPaymentGateway && selectedPaymentGateway.type !== 'NMI' && (
                            <div className="row full-width-a">
                                <button className="submit-btn" onClick={(e) => pay(e)}>
                                    Submit Your Payment
                                </button>

                            </div>)}

                        {selectedPaymentGateway && selectedPaymentGateway.type === 'NMI' && (
                            <div className="row full-width m-t-40">
                                <div style={{display: "flex", justifyContent: "space-around"}}>
                                    {paymentMethod === 'card' && (
                                        <button className="submit-btn forty-percent-width" onClick={(e) => {
                                            if (totalAmountDue > 0) {
                                                setPaymentMethod('card');
                                                pay(e)
                                            } else {
                                                showError({
                                                    errorMessage: 'Payment amount must be more than $0.00.',
                                                    errorTitle: 'Please Try Again'
                                                })
                                            }
                                        }}>
                                            Pay by Card
                                        </button>)}
                                    {paymentMethod === 'eCheck' && (
                                        <button className="submit-btn forty-percent-width" onClick={(e) => {
                                            if (totalAmountDue > 0) {
                                                setPaymentMethod('eCheck');
                                                setAchPopupOpen(true)
                                            } else {
                                                showError({
                                                    errorMessage: 'Payment amount must be more than $0.00.',
                                                    errorTitle: 'Please Try Again'
                                                })
                                            }
                                        }}>
                                            Pay by eCheck
                                        </button>)}
                                </div>
                            </div>)}

                    </div>


                </div>


            </section>

            {<Popup open={achPopupOpen} onClose={closeAchPopup} className="ach-popup" isAchAgree={isAchAgree}
                    setIsAchAgree={setIsAchAgree}>
                <p className={'m-t-20'}>By Clicking the “I Agree” box, you authorize {companyName || 'us'} to use
                    information from your check to initiate a one-time fund transfer from your
                    account or to process the payment as a check transaction or bank drawn draft
                    from your account for the amount of
                    ${amountFormatterWithZero(totalAmountDue || 0)}.
                    If your payment is
                    returned due to insufficient funds, you authorize us to make a one-time
                    electronic funds transfer or to use a bank draft drawn from your account to
                    collect a fee as allowed by state law.</p>
                <div className="flex-center">
                    <input type="checkbox" id='agree' value={isAchAgree}
                        // onChange={(e) => setForm({...form, agree: e.target.value})}
                           onChange={(e) => {
                               setIsAchAgree(!isAchAgree);
                               setForm({...form, agree: e.target.value});
                           }}
                    />
                    <label htmlFor='agree' className="m-l-10 m-t-0 bold">I Agree</label>
                </div>
                <button
                    disabled={!isAchAgree}
                    className="submit-btn ach-button"
                    onClick={(e) => pay(e)}
                >Continue to Payment
                </button>


                <span onClick={closeAchPopup}
                      className="close-button topright">&times;</span>
            </Popup>}
            <Popup open={open} onClose={closeModal}>
                <div>
                    <div className="icon-popup">
                        <svg width="50" height="50" x="0" y="0" viewBox="0 0 485.811 485.811">
                            <g>
                                <path
                                    d="M476.099,353.968l-170.2-294.8c-27.8-48.7-98.1-48.7-125.8,0l-170.3,294.8c-27.8,48.7,6.8,109.2,62.9,109.2h339.9   C468.699,463.168,503.899,402.068,476.099,353.968z M242.899,397.768c-14.8,0-27.1-12.3-27.1-27.1s12.3-27.1,27.1-27.1   c14.8,0,27.1,12.3,26.5,27.8C270.099,385.468,257.099,397.768,242.899,397.768z M267.599,222.568c-1.2,21-2.5,41.9-3.7,62.9   c-0.6,6.8-0.6,13-0.6,19.7c-0.6,11.1-9.3,19.7-20.4,19.7s-19.7-8-20.4-19.1c-1.8-32.7-3.7-64.8-5.5-97.5   c-0.6-8.6-1.2-17.3-1.9-25.9c0-14.2,8-25.9,21-29.6c13-3.1,25.9,3.1,31.5,15.4c1.9,4.3,2.5,8.6,2.5,13.6   C269.499,195.468,268.199,209.068,267.599,222.568z"
                                    fill="#ff4d4e" data-original="#000000"/>
                            </g>
                        </svg>
                    </div>
                    <div className="title-popup">
                        {errorTitle || "Transaction Declined"}
                    </div>
                    <div className="error-message">
                        {errorMessage}
                    </div>
                    <button className="close-btn" onClick={closeModal}>
                        Dismiss
                    </button>
                </div>
            </Popup>
            {showSpinner ? (<div className="popup-overlay">
                <svg className="spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                </svg>
            </div>) : null}
        </div>


    )
}

export default Checkout;
