import React, {useEffect} from 'react'
import {executeGet} from "../../Api";
import {useSearchParams} from "react-router-dom";

const Receipt = () => {
    const [transaction, setTransaction] = React.useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        executeGet("transaction/" + searchParams.get("transactionId") + "/?hostname=" + window.location.hostname)
            .then((res) => {
                setTransaction(res.data);
            });
    }, []);
    return (
        <div className="container-fluid overflow-auto">

            <section className="top-section">

                <div className="d-flex flex-column justify-content-center align-items-center">
                    <p>
                        <img className="img-fluid" src={require("../../assets/images/lock_icon.png")} alt="lock-icon"/>
                        <span className="checkout-text">Secure checkout process</span>
                    </p>
                </div>

                <div className="row d-flex flex-column justify-content-center align-items-center">
                    <div className="amount-due-block p-t-20 p-b-20 m-b-100">
                        {transaction ? (
                            <>
                                <h1 className="success-title">{transaction.receiptTitle || 'Receipt'}</h1>
                                <div style={{textAlign: 'center'}}>
                                    <p>Transaction status: {transaction.status}</p>
                                    <p>Name: {transaction.firstName} {transaction.lastName}</p>
                                    <p>Email: {transaction.email}</p>
                                    <p style={{textAlign: 'center'}}>Transaction ID: {transaction.transactionId}</p>
                                    <p>Amount: ${transaction.amount}</p>
                                    <p>Date: {(new Date(transaction.createdAt)).toLocaleString()}</p>
                                    {/*<div>*/}
                                    {/*    <a href={transaction.goHome} style={{borderBottom: 0}}>*/}
                                    {/*        <button className="btn btn-primary" style={{margin: "auto"}}>*/}
                                    {/*            Go Home*/}
                                    {/*        </button>*/}
                                    {/*    </a>*/}
                                    {/*</div>*/}
                                </div>
                            </>
                        ) : (
                            <p>Loading...</p>
                        )}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Receipt;
