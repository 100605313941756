import React from 'react';
import './footer.css';


const Footer = ({managerName, companyName, humanFriendlyName}) => {

    return (
        //fixed-bottom
        <div className="footer-container">
            <footer className="bg-light text-center text-lg-start no-gutter footer">
                <div className="text-center p-3" style={{backgroundColor: '#F9FAFB'}}>
                    <a className="text-dark footer-link footer-font" href="#">Privacy Policy</a>
                    <hr className="footer-hr"/>
                </div>
                <div className="text-center footer-font p-3" style={{backgroundColor: '#F9FAFB'}}>
                    © Copyright 2014-{(new Date).getFullYear() || '2024'} {managerName || ''}
                </div>
                <div>
                    <p className="text-center">{companyName || ''}</p>
                </div>

                <div>
                    <p className="text-center">{humanFriendlyName || ''}</p>
                </div>
                <div className="ellipse"></div>
            </footer>
        </div>
    )
}

export default Footer;
